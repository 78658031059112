export const formatRoleData = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.role?.id,
      name: obj?.role?.name,
      description: obj?.role?.description,
      userCount: obj?.users_count,
    });
  });

  return requiredResponse;
};

export const formatPermissionData = (response) => {

  const requiredResponse = [];
  response.permissions?.map((obj) => {
    requiredResponse.push({
      resource: obj?.resource,
      actions: obj?.actions,
    });
  });

  return requiredResponse;
};

export const retreivePermissionData = (response) => {
  const requiredResponse = [];
  response?.permission?.map((obj) => {
    requiredResponse.push({
      resource: obj?.resource,
      actions: obj?.actions,
      isVisible: obj?.visibility,
    });
  });

  return {
    requiredResponse,
    name: response?.role?.name,
    description: response?.role?.description,
    id: response?.role?.id,
  };
};

export const allEmptyPermission = (response) => {
  const requiredResponse = [];
  response?.permissions?.map((obj) => {
    requiredResponse.push({
      resource: obj?.resource,
      actions: obj?.actions,
      isVisible: obj?.visibility,
    });
  });

  return {
    requiredResponse,
  };
};

export const formateUserList = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      name: obj?.full_name,
      email: obj?.email,
      empCode: obj?.emp_code,
      designation: obj?.designation_name,
    });
  });

  return requiredResponse;
};

export const formatUsers = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      name: obj?.full_name,
      department: obj?.department,
      designation: obj?.designation,
      email: obj?.email,
      lastRole: obj?.last_role,
      empCode: obj?.emp_code,
      accountId: obj?.account_id,
    });
  });

  return requiredResponse;
};

export const formatUpdateRole = (response) => {
  const requiredResponse = [];
  response?.map((obj) => {
    requiredResponse.push({
      roleName: obj?.role,
      roleDescription: obj?.description,
    });
  });

  return requiredResponse;

};
